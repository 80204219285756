.CharacterCard {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin: 0px;
  border: 0px;
  background-color: #fff;
  box-shadow: 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05), 0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);
  border-radius: 50%;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  color: #333;
  font-weight: 400;
  transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0) scaleX(var(--scale-x, 1)) scaleY(var(--scale-y, 1));
  transform-origin: 0 0;
  touch-action: manipulation;
  cursor: grab;
}

.CharacterCard.dragging:not(.dragOverlay) {
  opacity: var(--dragging-opacity, 0.5);
  z-index: 0;
  /* cursor: grabbing; */
}

.CharacterCard.dragOverlay {
  cursor: grabbing;
  border-radius: 0px;
}