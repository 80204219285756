.UnplacedContainer {
  display: flex;
  flex-direction: column;
  grid-auto-rows: max-content;
  box-sizing: border-box;
  appearance: none;
  outline: none;
  border-radius: 5px;
  /* transition: background-color 350ms ease; */
  background-color: rgba(246, 246, 246, 1);
  border: 1px solid rgba(0, 0, 0, 0.05);
  font-size: 1em;
  min-height: 100%;
}
.UnplacedContainer ul {
  display: grid;
  grid-gap: 10px 5px;
  justify-content: start;
  grid-template-columns: repeat(var(--columns, 1), 1fr);
  padding: 10px 10px;
  margin: 0px;
  border: 0px;
  list-style-type: none;
  text-align: center;
}
